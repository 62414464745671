import React from "react"
import Flex from "@components/elements/flex"
import { graphql, useStaticQuery } from "gatsby"
import { processImages } from "@utils/process-images"
import Image from "@components/image"
import styled, { keyframes } from "styled-components"
import PropTypes from "prop-types"

const goLeft = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(1528px, 0, 0);
  }
`

const LeftAnim = styled.div`
  display: flex;
  gap: 10px;
  animation: ${goLeft} 48s linear infinite;
`

const goRight = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-1528px, 0, 0);
  }
`

const RightAnim = styled.div`
  display: flex;
  gap: 10px;
  animation: ${goRight} 48s linear infinite;
`

export const ServiceLibraryAnimation = ({ alt }) => {
  const data = useStaticQuery(
    graphql`
      query ServiceLibraryAnimation {
        images: allDirectusFiles(
          filter: {
            directus_id: {
              in: [
                "8f2924c2-ec0d-419c-970a-bb2b561497bc"
                "62338b7e-b15f-4c13-9a35-2f8d3e50c4a0"
                "bb9df11b-8249-41b5-9b3d-e894f45b7171"
                "756f395a-5c9e-42ce-bccb-ae71b0fba71e"
                "fd8d661f-b8fb-4592-ae76-5bf737154ebb"
                "c162376e-74d8-4e8d-934d-d6c77e3619f2"
                "ac9cfbe8-dc39-4aa2-b9c8-88394810209c"
                "6d8b657c-42dd-4c98-a7b4-c15c3647b5f1"
              ]
            }
          }
        ) {
          edges {
            node {
              directus_id
              cdn(width: 1518)
              id
              placeholder
              title
            }
          }
        }
      }
    `
  )

  const images = processImages(data.images.edges)

  const row1 = alt
    ? images["fd8d661f-b8fb-4592-ae76-5bf737154ebb"]
    : images["8f2924c2-ec0d-419c-970a-bb2b561497bc"]
  const row2 = alt
    ? images["c162376e-74d8-4e8d-934d-d6c77e3619f2"]
    : images["62338b7e-b15f-4c13-9a35-2f8d3e50c4a0"]
  const row3 = alt
    ? images["ac9cfbe8-dc39-4aa2-b9c8-88394810209c"]
    : images["bb9df11b-8249-41b5-9b3d-e894f45b7171"]
  const row4 = alt
    ? images["6d8b657c-42dd-4c98-a7b4-c15c3647b5f1"]
    : images["756f395a-5c9e-42ce-bccb-ae71b0fba71e"]

  return (
    <Flex
      maxWidth="100%"
      alignItems="center"
      zIndex={1}
      gap="1rem"
      style={{
        opacity: 0.9,
        filter:
          "drop-shadow(rgba(165, 171, 192, 0.2) 0px 0px 10px) drop-shadow(rgba(165, 171, 192, 0.4) 0px 2px 2px)",
        maskImage:
          "radial-gradient(65% 65% at 50% 50%, #FFFFFF 40%, rgba(255, 255, 255, 0) 75%)",
        WebkitMaskImage:
          "radial-gradient(65% 65% at 50% 50%, #FFFFFF 40%, rgba(255, 255, 255, 0) 75%)",
      }}
    >
      <LeftAnim>
        <Image file={row1} height={78} width={1518} loading="lazy" />
        <Image file={row1} height={78} width={1518} loading="lazy" />
        <Image file={row1} height={78} width={1518} loading="lazy" />
      </LeftAnim>
      <RightAnim>
        <Image file={row2} height={78} width={1518} loading="lazy" />
        <Image file={row2} height={78} width={1518} loading="lazy" />
        <Image file={row2} height={78} width={1518} loading="lazy" />
      </RightAnim>
      <LeftAnim>
        <Image file={row3} height={78} width={1518} loading="lazy" />
        <Image file={row3} height={78} width={1518} loading="lazy" />
        <Image file={row3} height={78} width={1518} loading="lazy" />
      </LeftAnim>
      <RightAnim>
        <Image file={row4} height={78} width={1518} loading="lazy" />
        <Image file={row4} height={78} width={1518} loading="lazy" />
        <Image file={row4} height={78} width={1518} loading="lazy" />
      </RightAnim>
    </Flex>
  )
}

ServiceLibraryAnimation.propTypes = {
  alt: PropTypes.bool,
}
