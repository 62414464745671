import React from "react"
import Flex from "@components/elements/flex"
import Container from "@components/layout/container"
import { graphql, useStaticQuery } from "gatsby"
import { processImages } from "@utils/process-images"
import Image from "@components/image"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import GradientText from "@components/common/gradient-text"
import Div from "@components/elements/div"

export const SocialProofSection = () => {
  const data = useStaticQuery(
    graphql`
      query SocialProofSection {
        images: allDirectusFiles(
          filter: {
            directus_id: { in: ["a558d470-c109-47d5-9c18-9d217cad4663"] }
          }
        ) {
          edges {
            node {
              ...imageData
            }
          }
        }
      }
    `
  )

  const images = processImages(data.images.edges)

  return (
    <Container>
      <Flex maxWidth="100%" gap="2rem" alignItems="center">
        <Flex gap="2rem" alignItems="center" position="relative" center>
          <Flex maxWidth="900px" alignItems="flex-start">
            <Heading
              style={{ fontSize: "clamp(2rem, 3vw + 1rem, 4rem)" }}
              as="h2"
              margin="0 auto 2rem"
              marginSm="0 auto 1rem"
            >
              We love what we do, and&nbsp;
              <GradientText
                gradient={
                  "linear-gradient(90deg, #707EFA 12%, #1E6EE6 52.5%, #18AED8 88%)"
                }
              >
                so do our customers
              </GradientText>
            </Heading>
            <Paragraph margin="0 auto 2rem" marginSm="0 auto 1rem">
              At Klipfolio, we believe everyone can make successful data-driven
              decisions. They just need the right tools. Our mission is to break
              down the barriers in data analytics and make it accessible to all,
              regardless of technical background or role.
            </Paragraph>
            <Paragraph>
              Recognized as a <b>front runner</b> (Software Advice), a&nbsp;
              <b>high performer</b> (G2 Crowd), and a <b>category leader</b>
              &nbsp;(GetApp) in Analytics and Business Intelligence.
            </Paragraph>
            <Div margin="3rem auto 0" marginSm="2rem auto 0">
              <Image
                eager
                radius={1}
                style={{ maxWidth: "60%", margin: "0 auto" }}
                file={images["a558d470-c109-47d5-9c18-9d217cad4663"]}
              />
            </Div>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  )
}
