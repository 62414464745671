import React from "react"
import Layout from "@components/layout"
import Paragraph from "@components/elements/paragraph"
import Heading from "@components/elements/heading"
import Div from "@components/elements/div"
import Button from "@components/elements/button"
import Grid from "@components/elements/grid"
import { Logo } from "@components/common/logo"
import Anchor from "@components/elements/anchor"
import Flex from "@components/elements/flex"
import { SocialProofSection } from "@sections/home/social-proof"
import styled, { useTheme } from "styled-components"
import { HighGradient } from "@components/common/gradient"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { processImages } from "@utils/process-images"
import Image from "@components/image"
import { ServiceLibraryAnimation } from "@components/animated/service-library"
import { NeutralCTA } from "@components/cta/eop/neutral"
import media from "../styles/media"

const ProductWrapper = styled.div`
  @media only screen and (min-width: 1400px) {
    padding-bottom: 4rem;
    background: linear-gradient(
      ${({ theme }) => theme.color.indigo700},
      #081a2a
    );
  }
`

const ProductContainer = styled.div`
  @media only screen and (min-width: 1400px) {
    margin: 0 auto;
    max-width: 1400px;
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.shadow.glassy};
  }
`

const ImageWrapper = styled(Div)`
  ${media.md_up`
    height: 30vh;
    min-height: 250px;
    max-height: 380px;
  `}
`

const ProductGrid = styled(Grid)`
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`

const Subtitle = styled(Paragraph)`
  ${media.md_up`
    max-width: 80%;
  `}
`

const HomePageV2 = ({ data }) => {
  const { color, gradient } = useTheme()
  const images = processImages(data.images.edges)

  return (
    <Layout
      fullWidth
      marginless
      login
      seo={{
        title: "Make Data Everybody’s Business",
        description: `Klipfolio helps data teams provide curated, trusted data and straightforward self-serve analytics to business users and decision makers.`,
        tracking: ["noindex", "nofollow", "noarchive"],
      }}
    >
      <Div
        position="relative"
        background={color.indigo700}
        maxWidth="100%"
        width="100%"
        overflow="hidden"
      >
        <Div
          left="0"
          right="0"
          maxWidth="1600px"
          position="absolute"
          width="100%"
          top="-8rem"
          showOnMd
        >
          <HighGradient
            style={{ minWidth: "800px" }}
            top="18rem"
            src={data.gradient.cdn}
          />
        </Div>
        <Div
          left="42%"
          right="0"
          maxWidth="1600px"
          position="absolute"
          width="100%"
          top="-4rem"
          hideOnMd
        >
          <HighGradient
            style={{
              maxHeight: "800px",
              transform: "rotate(70deg)",
              top: "-15rem",
              right: "25%",
              left: "unset",
            }}
            src={data.gradient.cdn}
          />
          <Div
            style={{
              maskImage: "linear-gradient(90deg, transparent 10%, white 30%)",
            }}
          >
            <Image
              objectFit="cover"
              file={data.hero}
              height={1000}
              style={{
                maskImage:
                  "linear-gradient(180deg, white 30%, transparent 85%)",
              }}
            />
          </Div>
        </Div>
        <Div container>
          <Flex
            gap="1rem"
            padding="10rem 0 6rem"
            paddingMd="8rem 0 3rem"
            position="relative"
            margin="0"
            maxWidth="780px"
          >
            <Heading
              as="h1"
              color="white"
              style={{
                textShadow: "0px 4px 25px rgba(33, 38, 79, 0.50)",
                fontSize: "clamp(2rem, 3vw + 1.5rem, 4.8rem)",
              }}
            >
              Succeed with data
            </Heading>
            <Subtitle
              color="white"
              fontSize="1.5rem"
              lineHeight="160%"
              fontWeight={600}
            >
              Better decision making for every organization. Simple for business
              users. Simply incredible for data teams.
            </Subtitle>
            <Paragraph
              color="white"
              fontSize="1.5rem"
              lineHeight="160%"
              fontWeight={600}
              margin="1rem 0"
            >
              We’ve got you covered.
            </Paragraph>
            <Button.Agnostic
              background={gradient.powerMetricsButtonAlt}
              boxShadow="0px 8px 16px rgba(75, 87, 197, 0.2), inset 0px -6px 14px rgba(255, 255, 255, 0.4)"
              color={color.indigo600}
              margin="0 0 3rem"
              marginMd="0 0 2rem"
              width="max-content"
              noticeText="No credit card required"
              noticeColor="white"
              data-cta-location="header"
            />
            <Paragraph fontWeight={600} color="white" lineHeight={1.6}>
              You’ll be in great company with over 1,600 other happy customers
            </Paragraph>
            <Image
              style={{ maxWidth: "500px", marginLeft: "-1rem" }}
              file={images["66b5199c-9436-470d-8d01-cf1bcad4a103"]}
            />
          </Flex>
        </Div>
      </Div>
      <ProductWrapper>
        <ProductContainer>
          <ProductGrid columns="repeat(2, 1fr)">
            <Flex
              background="linear-gradient(180deg, #21264F 0%, #0F1124 100%)"
              padding="4rem"
              paddingMd="3rem"
              paddingSm="2rem"
              gap="1rem"
              alignItems="flex-start"
            >
              <Logo.PowerMetrics />
              <Paragraph
                fontSize="1.5rem"
                mobileScale={0.7}
                color={color.indigo300}
              >
                Top choice for the modern analytics stack
              </Paragraph>
              <ImageWrapper
                margin="1rem 0 1rem -4rem"
                marginSm="0.5rem 0"
                background="#B0B8FC"
                borderRadius="0 0.5rem 0.5rem 0"
                borderRadiusMd="0.5rem"
                overflow="hidden"
              >
                <Image
                  style={{ maxHeight: "unset" }}
                  file={images["f45e2995-4338-48b6-bd26-94832e1d2739"]}
                />
              </ImageWrapper>
              <Heading as="h2" color="white">
                Metric catalog & analytics
              </Heading>
              <Paragraph
                fontSize="1.3rem"
                lineHeight="1.5"
                color="white"
                margin="0 0 1rem"
                marginSm="0 0 0.5rem"
              >
                Put data analysis and dashboard creation into the hands of
                business users with curated metrics, governed by the data team.
              </Paragraph>
              <Flex
                margin="auto 0 0"
                flexFlow="row"
                gap="1rem"
                alignItems="center"
              >
                <Button.PowerMetrics data-cta-location="product-selector" notice={false}>
                  Get Started
                </Button.PowerMetrics>
                <Anchor
                  style={{ alignItems: "center", display: "flex" }}
                  color="white"
                  arrow
                  link="/powermetrics"
                >
                  Learn more
                </Anchor>
              </Flex>
            </Flex>
            <Flex
              background="linear-gradient(180deg, #013950 0%, #012330 100%)"
              padding="4rem"
              paddingMd="3rem"
              paddingSm="2rem"
              gap="1rem"
              alignItems="flex-start"
            >
              <Logo.Klips />
              <Paragraph
                color={color.blue300}
                mobileScale={0.7}
                fontSize="1.5rem"
              >
                Best for small businesses
              </Paragraph>
              <ImageWrapper
                margin="1rem -4rem 1rem 0"
                marginSm="0.5rem 0"
                background="#9EE6FA"
                borderRadius="0.5rem 0 0 0.5rem"
                borderRadiusMd="0.5rem"
                overflow="hidden"
              >
                <Image
                  style={{ maxHeight: "unset" }}
                  file={images["2bc66d7b-8bc4-47ce-ae7c-e02421053eff"]}
                />
              </ImageWrapper>
              <Heading as="h2" color="white">
                Dashboards & reports
              </Heading>
              <Paragraph
                fontSize="1.3rem"
                lineHeight="1.5"
                color="white"
                margin="0 0 1rem"
                marginSm="0 0 0.5rem"
              >
                Build and manage highly customizable dashboards and reports for
                your team and clients.
              </Paragraph>
              <Flex
                margin="auto 0 0"
                flexFlow="row"
                gap="1rem"
                alignItems="center"
              >
                <Button.Klips data-cta-location="product-selector" notice={false} noticeColor="white">
                  Get Started
                </Button.Klips>
                <Anchor
                  style={{ alignItems: "center", display: "flex" }}
                  color="white"
                  arrow
                  link="/klips"
                >
                  Learn more
                </Anchor>
              </Flex>
            </Flex>
          </ProductGrid>
        </ProductContainer>
      </ProductWrapper>
      <Div margin="8rem auto" marginMd="6rem auto" marginSm="4rem auto">
        <SocialProofSection />
      </Div>
      <Div
        container
        position="relative"
        overflow="hidden"
        margin="0 auto 8rem"
        marginMd="0 auto 6rem"
        marginSm="0 auto 4rem"
        maxWidth="100%"
      >
        <Heading
          style={{ fontSize: "clamp(2rem, 3vw + 1rem, 4rem)" }}
          margin="0 0 3rem"
          marginSm="0 0 2rem"
          as="h2"
          center
        >
          100+ Technology Partners
        </Heading>
        <ServiceLibraryAnimation />
      </Div>
      <NeutralCTA />
    </Layout>
  )
}

HomePageV2.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HomePageV2

export const pageQuery = graphql`
  query HomePageQuery {
    gradient: directusFiles(
      directus_id: { eq: "56ded7c2-7a1c-4c29-9a69-5ce23a09f1af" }
    ) {
      id
      cdn
      title
    }
    hero: directusFiles(
      directus_id: { eq: "e6a3b3f5-ce63-4af7-9ad3-620c6bdaca84" }
    ) {
      id
      cdn
      title
    }
    icons: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "b017da8b-6c31-47ff-a77a-9f75cf6fb353"
            "3efdd553-4448-4e9e-9e5f-dabf2647b45c"
            "3aa397c0-c54c-4c24-9d6a-fa2e7641dfe9"
            "9e4c6e11-0e74-45b6-8557-3f3291b6ec1f"
            "944f1830-d5f3-42bd-9d97-247790e18cef"
            "cf9cfc3a-a202-416c-a1bf-d7aa15484c0f"
            "40dc1991-0dde-461c-b6f6-0fe65f99779e"
            "e6486f9a-8339-451c-bea4-20d70118ed2d"
          ]
        }
      }
    ) {
      edges {
        node {
          cdn
          title
          placeholder
          directus_id
        }
      }
    }
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "f45e2995-4338-48b6-bd26-94832e1d2739"
            "2bc66d7b-8bc4-47ce-ae7c-e02421053eff"
            "66b5199c-9436-470d-8d01-cf1bcad4a103"
          ]
        }
      }
    ) {
      edges {
        node {
          cdn
          title
          placeholder
          directus_id
        }
      }
    }
  }
`
